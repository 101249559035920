import { clsx } from 'clsx'
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { FC, memo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Link } from '@headless/ui'
import { event } from '@headless/utils'

import CurrencyControll from '@components/CurrencyControll'
import {
  StyledCurrencyContainer,
  StyledNav,
  StyledNavColumn,
  StyledShade
} from './styled'

interface NavProps {
  categories: any[]
  onMobileClickOutside: () => void
}

const NuxtLink = ({ href, ...props }: any) => <Link href={href} {...props} />

const MobileNav: FC<NavProps> = ({ categories, onMobileClickOutside }) => {
  const storeConfig = useSelector((state: any) => state.app.storeConfig)

  const handleRedirect = (e: Event) => {
    e.stopPropagation()
    const layerEle: any = document.querySelector('.nav__layer')
    const megaEle: any = window.$('.dropdown-mega-menu')

    layerEle.classList.remove('active')
    megaEle.removeClass('active')
    event.emit('closeDrawer')
  }

  const options: HTMLReactParserOptions = {
    replace: (node: any) => {
      const classes = node.attribs?.class
      if (classes && classes.split(' ').indexOf('mega-menu-content') > -1) {
        return <></>
      }

      if (node.name === 'a') {
        const match: string = node.attribs.href.match(/.com(\S*)/)?.[1] ?? ''
        const href: string = match.replace(/\/\//g, '/')

        if (!node.parent && node.nextSibling) {
          return (
            <span className={node.attribs?.class ?? ''}>
              {domToReact(node.children, options)}
            </span>
          )
        }

        return (
          <NuxtLink
            href={href}
            className={node.attribs?.class ?? ''}
            title={node.attribs?.title ?? ''}
            onClick={handleRedirect}
          >
            {domToReact(node.children, options)}
          </NuxtLink>
        )
      }
    }
  }

  useEffect(() => {
    const toggleEle: any = document.querySelectorAll('.nav__column')
    const closeEle: any = document.querySelectorAll(
      '.sub-menu > li:first-child'
    )

    if (toggleEle) {
      for (let i = 0; i < toggleEle.length; i++) {
        toggleEle[i].onclick = () => {
          const targetEle: any = toggleEle[i].querySelector(
            '.dropdown-mega-menu'
          )
          if (targetEle) targetEle.classList.add('active')
        }
      }
    }
    if (closeEle) {
      for (let i = 0; i < closeEle.length; i++) {
        closeEle[i].onclick = (e: Event) => {
          e.stopPropagation()
          closeEle[i].parentNode.parentNode.parentNode.classList.remove(
            'active'
          )
        }
      }
    }
  }, [])

  const onClickShade = () => {
    onMobileClickOutside()
  }

  return (
    <>
      <StyledNav className="nav__layer">
        {categories.length > 0 && (
          <ul className="nav__menu">
            {categories.map((category: any) => {
              const className: string = category.title
                .toLowerCase()
                .replace(/ /g, '_')
              const imageName: string = category.title
                .toUpperCase()
                .replace(/ /g, '')
              return (
                <StyledNavColumn
                  className={clsx({
                    nav__column: true,
                    [className]: true
                  })}
                  key={category.id}
                  background={`${storeConfig.secure_base_media_url}wysiwyg/slider/menu/m/${imageName}_gn.png`}
                >
                  <div className={`nav__item nav__item__${category.id}`}>
                    <div className={`nav__content nav__${className}`}>
                      {parse(category.content, options)}
                    </div>
                  </div>
                </StyledNavColumn>
              )
            })}
          </ul>
        )}
        <StyledCurrencyContainer>
          <CurrencyControll />
        </StyledCurrencyContainer>
      </StyledNav>
      <StyledShade className="nav__shade" onClick={onClickShade} />
    </>
  )
}

export default memo(MobileNav)
